<template lang="">
  <div
    class="header-container"
    :style="{ background: backgroundColor, height: headerHeight / 144 + 'rem' }"
    @mouseleave="onMouseLeave"
  >
    <div class="container">
      <div class="nav-logo" @click="onClickLogo">
        <img src="@/assets/logo.png" />
      </div>
      <div class="nav">
        <div
          v-for="(item, index) in navData"
          :key="index"
          class="button nav-title"
          @click="onNavClick(index)"
          @mouseenter="onMouseEnter($event, index)"
        >
          <span>{{ item.title }}</span>
          <div
            v-if="index === tabIndex && backgroundColor !== 'transparent'"
            class="nav-underline"
          />
        </div>
        <div class="nav-title" @mouseenter="onMouseEnter($event, 99)">
          小程序/APP
        </div>
        <div class="nav-login" @click="onCompanyLogin">企业登录</div>
        <div class="nav-login nav-button" @click="onLoginButton">入围中石油能量站平台</div>
        <div
          v-if="isShowMenu"
          class="menu"
          :style="{
            top: headerHeight / 144 + 'rem',
            left: menuX + 'px',
          }"
        >
          <div
            v-for="(item, index) in menuData"
            :key="index"
            class="item"
            :class="{ active: menuIndex === index }"
            type="text"
            @click="onMenuClick(item.path, index)"
          >
            {{ item.title }}
          </div>
        </div>
        <div
          class="weapp"
          v-if="isShowWeapp"
          :style="{ top: (headerHeight - 10) / 144 + 'rem' }"
        >
          <div class="weapp-item">
            <img src="@/assets/app_qrcode1.png" />
            <span>小程序二维码</span>
          </div>
          <div class="weapp-item">
            <img src="@/assets/app_qrcode2.png" />
            <span>APP下载二维码</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const HEADER_HEIGHT = 64;
export default {
  name: "CustomHeader",
  props: {
    backgroundColor: String,
  },
  data() {
    const navData = [
      { name: "Home", path: "/", title: "首页" },
      { name: "Service", path: "/service", title: "产品服务" },
      { name: "Cooperation", path: "/cooperation", title: "渠道合作" },
      { name: "About", path: "/about", title: "关于能量站" },
    ];
    const menuData = [
      { name: "ServiceWeapp", path: "/service/weapp", title: "小程序/APP" },
      // { name: "ServiceSaas", path: "/service/saas", title: "湾云saas" },
      { name: "ServiceCompany", path: "/service/company", title: "企业平台" },
    ];
    return {
      headerHeight: HEADER_HEIGHT,
      tabIndex: 0,
      navData,
      menuX: 0,
      isShowMenu: false,
      menuIndex: undefined,
      menuData,
      isShowWeapp: false,
    };
  },
  created() {
    this.$emit("getHeight", HEADER_HEIGHT);
    const route = this.$route;
    if (route.name.startsWith("Service")) {
      this.tabIndex = this.navData.findIndex((item) => item.name === "Service");
      this.menuIndex = this.menuData.findIndex(
        (item) => item.name === route.name
      );
    } else {
      this.tabIndex = this.navData.findIndex(
        (item) => item.name === route.name
      );
    }
  },
  methods: {
    onCompanyLogin() {
      window.open("https://apps.wosongjiu.com")
    },
    onClickLogo() {
      location.href = "/";
    },
    onNavClick(index) {
      const { name, path } = this.navData[index];
      if (name === "Service") {
        return;
      }
      this.$router.push({ path });
    },
    onMouseEnter(event, index) {
      if (index === 99) {
        this.isShowWeapp = true;
        return;
      }
      this.isShowWeapp = false;
      const { name } = this.navData[index];
      if (name !== "Service") {
        this.isShowMenu = false;
        return;
      }
      const { offsetLeft } = $(event.currentTarget)[0];
      this.menuX = offsetLeft - 50;
      this.isShowMenu = true;
    },
    onMouseLeave() {
      this.isShowMenu = false;
      this.isShowWeapp = false;
    },
    onMenuClick(path, index) {
      this.$router.push({ path });
      this.menuIndex = index;
      this.tabIndex = this.navData.findIndex((item) => item.name === "Service");
      this.isShowMenu = false;
    },
    onLoginButton() {
      window.open("https://apps.wosongjiu.com/eweb/merch/#/views/merchApply/index")
    }
  },
};
</script>
<style lang="scss">
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #175499;
  background: #1B2389;
  overflow: visible;
  z-index: 9999;
  width: 1440px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;

    .nav-logo {
      width: 209px;
      height: 22px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 60px;

      .nav-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        min-width: 40px;

        &+.nav-title {
          margin-left: 40px;
        }

        .nav-underline {
          position: absolute;
          bottom: -16px;
          left: 0;
          right: 0;
          margin: auto;
          width: 40px;
          height: 4px;
          background: #ffffff;
          border-radius: 3px;
        }
      }

      .nav-login {
        width: 83px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin-left: 40px;

        &:hover {
          cursor: pointer;
        }
      }

      .nav-button {
        width: unset;
        padding: 0 12px;
        margin-left: 20px;
      }

      .menu {
        position: absolute;
        width: 304px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 6px 6px;
        display: flex;
        flex-direction: column;
        padding: 44px;

        .item {
          &:hover {
            cursor: pointer;
            color: #175499;
          }

          font-size: 18px;
          font-weight: 500;
          color: #222222;
          text-align: left;
          padding: 0;

          &.active {
            color: #175499;
          }

          &+.item {
            margin-top: 14px;
            margin-left: 0;
          }
        }
      }

      .weapp {
        position: absolute;
        right: 440px;
        display: flex;
        background: #ffffff;
        padding: 24px;
        border-radius: 6px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

        &:before {
          content: "";
          position: absolute;
          top: -10px;
          right: 80px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #ffffff;
          z-index: 9;
        }

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &+.weapp-item {
            margin-left: 40px;
          }

          &>img {
            width: 124px;
            height: 124px;
            background: #e5e5e5;
          }

          &>span {
            font-size: 14px;
            font-weight: bold;
            color: #222222;
            margin-top: 12px;
          }
        }
      }
    }
  }
}
</style>
