import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Service from "@/views/Service.vue";
import About from "@/views/About.vue";
import Cooperation from "@/views/Cooperation.vue";
import ServiceWeapp from "@/views/ServiceWeapp.vue";
import ServiceSaas from "@/views/ServiceSaas.vue";
import ServiceCompany from "@/views/ServiceCompany.vue";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/about", name: "About", component: About },
  {
    path: "/service",
    name: "Service",
    component: Service,
    children: [
      { path: "/service/weapp", name: "ServiceWeapp", component: ServiceWeapp },
      { path: "/service/saas", name: "ServiceSaas", component: ServiceSaas },
      {
        path: "/service/company",
        name: "ServiceCompany",
        component: ServiceCompany,
      },
    ],
  },
  { path: "/cooperation", name: "Cooperation", component: Cooperation },
];

const router = createRouter({
  mode: "history",
  history: createWebHashHistory(),
  routes,
});

router.afterEach(() => {
  let bodySrcollTop = document.body.scrollTop;
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0;
    return;
  }
  let docSrcollTop = document.documentElement.scrollTop;
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

export default router;
