<template>
  <div class="company-join-container">
    <img class="bg" src="@/assets/join-bg.png" />
    <div class="join-title">成为能量人</div>
    <div class="join-desc">期待您成为我们的一员，共同建造这个大家庭</div>
    <div class="join-list">
      <div class="join-item" v-for="(item, index) in joinData" :key="index">
        <span class="item-group">{{ item.group }}</span>
        <span
          class="item-name"
          v-for="(e, i) in item.positions"
          :key="i"
          @click="clickJoin"
        >
          {{ e.name }}
        </span>
      </div>
    </div>
    <div class="button join-button" @click="clickJoin">加入能量站</div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    const joinData = [
      {
        group: " 产品中心",
        positions: [{ name: "UI设计师" }],
      },
      {
        group: "市场中心",
        positions: [{ name: "渠道经理" }],
      },
      {
        group: "研发中心",
        positions: [
          { name: "Java工程师" },
          { name: "Android工程师" },
          { name: "ios工程师" },
          { name: "PHP工程师" },
        ],
      },
      {
        group: "营建中心",
        positions: [{ name: "营建经理" }, { name: "普通店务设计师" }],
      },
      {
        group: "线下运营中心",
        positions: [
          { name: "直营区域经理" },
          { name: "直营门店督导" },
          { name: "客户区域经理" },
          { name: "店长岗（直营/ToG）" },
          { name: "门店导购岗（直营/ToG）" },
        ],
      },
      {
        group: "供应链中心",
        positions: [{ name: "供应链c采购经理" }],
      },
      {
        group: "线上运营中心",
        positions: [{ name: "高级大客户经理" }],
      },
    ];
    return { joinData };
  },
  methods: {
    clickJoin() {
      window.open(
        "https://www.zhipin.com/job_detail/?query=浙江昆仑大数据信息技术有限公司&city=100010000&industry=&position="
      );
    },
  },
};
</script>
<style lang="scss">
.company-join-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 515px;
  position: relative;
  & > img.bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .join-title {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    margin-top: 44px;
  }
  .join-desc {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    margin-top: 18px;
  }
  .join-list {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin-top: 50px;
    .join-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 239px;
      &:last-child {
        position: relative;
        top: -80px;
      }
      &:nth-last-child(2) {
        position: relative;
        top: -80px;
      }
      .item-group {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 22px;
      }
      .item-name {
        font-size: 16px;
        font-weight: lighter;
        color: #ffffff;
        line-height: 22px;
        & + .item-name {
          margin-top: 16px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .join-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: 0 auto;
    width: 141px;
    height: 43px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    border: 1px solid #ffffff;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
  }
}
</style>
