<template>
  <div class="company-photo-container">
    <div class="title">团队风采</div>
    <div class="desc">团建及日常工作照片</div>
    <Swiper
      class="swiper"
      slides-per-view="2.8"
      :space-between="-120"
      :centered-slides="true"
      :centered-slides-bounds="true"
      :loop="true"
      :observer="true"
      :observe-parents="true"
      :pagination="{
        type: 'custom',
        el: '.swiper-pagination',
        renderCustom: renderCustomPagination,
      }"
      :navigation="{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }"
      :autoplay="{
        delay: 3000,
      }"
    >
      <SwiperSlide
        class="slide"
        v-for="(item, index) in photoData"
        :key="index"
      >
        <div class = "slide-img-div">
          <img class="slide-img" :src="item.image" />
          <div class="mask"></div>
        </div>
      </SwiperSlide>
      <!-- <div class="swiper-pagination"></div> -->
      <img class="swiper-button-prev" src="@/assets/prev.png" />
      <img class="swiper-button-next" src="@/assets/next.png" />
    </Swiper>
  </div>
</template>
<script>
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
SwiperCore.use([Autoplay, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    // const photoData = Array(13)
    //   .fill("")
    //   .map((_, index) => ({
    //     title: "",
    //     image: require(`@/assets/photos/photo${index + 1}.jpg`),
    //   }));

    const photoData = [
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/d21df129-4b5d-4072-96df-b1a0093f64f9.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/66798a85-cfc9-42d9-bfa9-a4e9f496ca8e.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/3b5f188b-3a62-498d-b0e9-8931e6101ca6.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/5e1cef62-e33c-49d4-838f-d424500d6030.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/76065604-076f-4a8b-8155-e78876c73f3a.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/f781d8f4-e4b2-42a5-be02-f21616ab733f.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/a6a53e36-ceb0-4981-87a3-4fd937a573a7.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/7d7f48f8-1c43-40f0-81c7-fbe25b6dc2c6.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/f3342a20-ca76-4e74-b3c4-83e430307b5c.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/017f9fbf-d258-41b8-b478-58e67a820614.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/2b947bc4-25b7-474e-9f32-587d5174d8a0.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/3e4eafae-4769-458b-9c9b-9452a833c7aa.jpg" },
      { title: "", image: "https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/764d48a4-2649-4e9a-89a0-c19d40e8f645.jpg" },
    ];
    return { photoData };
  },
  methods: {
    renderCustomPagination(_, current) {
      const title = this.photoData[current - 1].title;
      let paginationHtml = `<div class="pagination-wrap">`;
      paginationHtml += `
        <div class="pagination">
          <span>${title}</span>
        </div>`;
      paginationHtml += "</div>";
      return paginationHtml;
    },
  },
};
</script>
<style lang="scss" scope>
.company-photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
  }
  .desc {
    font-size: 18px;
    font-weight: bold;
    color: #0a2349;
    line-height: 25px;
  }
  .swiper {
    width: 1200px;
    height: 297px;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
    .swiper-slide-active {
      &.slide {
        transform: scale(1);
        z-index: 999;
      }
    }
    .slide {
      border-radius: 11px;
      overflow: hidden;
      width: 560px;
      border-radius: 8px;
      height: 100%;
      transform: scale(0.6);
      background: #f7f7f7;
      & > div {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .slide-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: rgba(17, 18, 73, 0.3);
      }
    }
    .swiper-slide-prev {
      .mask{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        background: rgba(17, 18, 73, 0.3);
      }
    }
    .swiper-slide-next {
      .mask{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        background: rgba(17, 18, 73, 0.3);
      }
    }
    .swiper-pagination {
      bottom: -80px;
      .pagination-wrap {
        display: flex;
        justify-content: center;
        .pagination {
          max-width: 560px;
          span {
            font-size: 30px;
            font-weight: 500;
            color: #01143b;
            line-height: 42px;
          }
        }
      }
    }
    .swiper-button-prev {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 136px;
      left: 90px;
      z-index: 999;
    }
    .swiper-button-next {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 136px;
      right: 90px;
      z-index: 999;
    }
  }
}
</style>
