import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./style/resrt.css";
import "./lib/lib-flexible";

var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?d0e219d0d1fd6bf2ea5c8fecc0a435bd";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

createApp(App)
  .use(ElementPlus)
  .use(store)
  .use(router)
  .mount("#app");
