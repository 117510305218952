<template>
  <div class="service-saas-container">
    <div class="title">功能强大的电商系统</div>
    <div class="sub-title">Powerful e-commerce system</div>
    <div class="show">
      <img class="bg" src="@/assets/saas-bg.png" />
      <div class="tabs">
        <div
          class="item"
          :class="{ active: showTabIndex === index }"
          v-for="(item, index) in showData"
          :key="index"
          @click="showTabIndex = index"
        >
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="show-content">
        <span class="content-title">{{ showData[showTabIndex].title }}</span>
        <span class="content-title">{{ showData[showTabIndex].sTitle }}</span>
        <span class="content-desc">{{ showData[showTabIndex].desc }}</span>
        <span class="content-mdesc">{{ showData[showTabIndex].sDesc }}</span>
      </div>
    </div>
    <div class="step-menu">
      <div class="item" v-for="(item, index) in steps" :key="index">
        <span class="item-title" @click="clickAnchor('#step' + item.index)">
          {{ item.menuTitle }}
        </span>
        <img class="item-img" src="@/assets/step.png" />
      </div>
      <div class="item">
        <span class="item-title">线上全渠道开店</span>
      </div>
    </div>
    <div
      class="step-content"
      :class="['step' + item.index, item.index % 2 == 0 ? 'reverse' : '']"
      :id="'step' + item.index"
      v-for="(item, index) in steps"
      :key="index"
    >
      <div>
        <div class="info">
          <img class="step-number" :src="item.numberImage" />
          <span class="step-title">{{ item.title }}</span>
          <span class="step-desc">{{ item.desc }}</span>
        </div>
        <div class="image">
          <img :src="item.image" />
        </div>
      </div>
    </div>
    <div class="title">解决方案</div>
    <div class="case1">
      <div class="item-1">周期购</div>
      <div class="item-2">新媒体</div>
      <div class="item-3">百货商超</div>
      <div class="item-4">连锁酒店</div>
      <div class="item-4">票务卡券</div>
      <div class="item-5">教育培训</div>
    </div>
    <div class="title">解决方案</div>
    <div class="case2">
      <div class="item" v-for="(item, index) in caseData" :key="index">
        <div class="cover">
          <img />
          <span class="item-title">{{ item.title }}</span>
        </div>
        <div class="item-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const showData = [
      {
        title: "系统数据分析",
        sTitle: "System data analysis",
        desc: "流量分析、交易分析、客户行为分析，全方位数据",
        sDesc:
          "Flow analysis, transaction analysis, customer behavior analysis, comprehensive data Analysis, driving decision-making adjustment.",
      },
      {
        title: "广告投放",
        sTitle: "",
        desc: "",
        sDesc: "",
      },
    ];
    const steps = [
      {
        index: 1,
        numberImage: require("@/assets/step1.png"),
        menuTitle: "线上全渠道开店",
        title: "线上全渠道开店海量曝光",
        desc: "微信、QQ、微博、小程序，把握社交网络每一份流量",
        image: "",
      },
      {
        index: 2,
        numberImage: require("@/assets/step2.png"),
        menuTitle: "店铺组件化装修",
        title: "店铺页面组件化装修，满足个性化展现",
        desc: "微信、QQ、微博、小程序，把握社交网络每一份流量",
        image: "",
      },
      {
        index: 3,
        numberImage: require("@/assets/step3.png"),
        menuTitle: "数百种营销玩法",
        title: "数百种营销玩法，提升成交",
        desc:
          "拉新、转化、促活、复购、推广，专业工具助力成交，店铺经营面面俱到",
        image: "",
      },
      {
        index: 4,
        numberImage: require("@/assets/step4.png"),
        menuTitle: "系统化管理客户",
        title: "系统化管理客户，更多会员更多成交",
        desc:
          "客户标签画像，帮你了解你的客户：会员卡/储值卡/会员权益，精准营销拉升客单",
        image: "",
      },
      {
        index: 5,
        numberImage: require("@/assets/step5.png"),
        menuTitle: "拓展销售渠道",
        title: "分销拓展销售渠道，让更多的人帮你卖货",
        desc: "你的员工帮你卖；你的客户帮你卖；300万商家帮你卖",
        image: "",
      },
    ];
    const caseData = [
      {
        image: "",
        title: "专业客服答疑",
        desc: "7*12小时专业指导答疑解惑",
      },
      {
        image: "",
        title: "线上视频教学",
        desc: "随时谁地学习提升能力",
      },
      {
        image: "",
        title: "同行交流活动",
        desc: "直播、社区、商盟，实战分享",
      },
      {
        image: "",
        title: "运营者特训营",
        desc: "培训优良运营人群轻松招人",
      },
    ];
    return { showData, steps, caseData, showTabIndex: 0 };
  },
  methods: {
    clickAnchor(selector) {
      const anchor = this.$el.querySelector(selector);
      $("html,body").animate({ scrollTop: anchor.offsetTop }, 500);
    },
  },
};
</script>
<style lang="scss">
.service-saas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 100px;
  }
  .sub-title {
    font-size: 18px;
    font-weight: bold;
    color: #0a2349;
    line-height: 25px;
    margin-top: 30px;
  }
  .show {
    display: flex;
    width: 1200px;
    height: 430px;
    margin-top: 60px;
    position: relative;
    & > img.bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .tabs {
      padding-top: 80px;
      background: rgba(255, 255, 255, 0.07);
      .item {
        width: 246px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: #aecfff;
        line-height: 33px;
        border-left: 6px solid transparent;
        &:hover {
          cursor: pointer;
        }
        &.active {
          background: rgba(29, 110, 191, 0.38);
          border-left: 6px solid #32e8ff;
        }
      }
    }
    .show-content {
      display: flex;
      flex-direction: column;
      padding: 70px 0 0 50px;
      max-width: 450px;
      .content-title {
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
      }
      .content-desc {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
        margin-top: 40px;
      }
      .content-mdesc {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 25px;
      }
    }
  }
  .step-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    height: 98px;
    background: #fafafa;
    border: 1px solid #e5e7eb;
    margin-top: 120px;
    .item {
      display: flex;
      align-items: center;
      .item-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #222222;
        &:hover {
          cursor: pointer;
        }
        &:last-child:hover {
          cursor: default;
        }
      }
      .item-img {
        width: 27px;
        height: 30px;
        margin: 0 10px;
      }
    }
  }
  .step-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 160px 0 100px;
    & > div {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .info {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 600px;
        .step-number {
          position: absolute;
          top: 20px;
        }
        .step-title {
          font-size: 26px;
          font-weight: bold;
          color: #222222;
          line-height: 42px;
          margin-top: 30px;
          margin-left: 40px;
          z-index: 10;
        }
        .step-desc {
          font-size: 20px;
          font-weight: 500;
          color: #4c5875;
          line-height: 33px;
          margin-top: 20px;
          margin-left: 40px;
          z-index: 10;
        }
      }
      .image {
        width: 496px;
        height: 290px;
        background: chartreuse;
        img {
          display: flex;
          width: 100%;
          height: 100%;
        }
      }
    }
    &.reverse {
      background: #f9f9f9;
      padding: 140px 0 90px;
      & > div {
        flex-direction: row-reverse;
        .info {
          .step-title {
            margin-top: 30px;
          }
        }
      }
    }
    &.step1 {
      .info .step-number {
        width: 58px;
        height: 116px;
      }
    }
    &.step2 {
      .info .step-number {
        width: 129px;
        height: 114px;
      }
    }
    &.step3 {
      .info .step-number {
        width: 130px;
        height: 114px;
      }
    }
    &.step4 {
      .info .step-number {
        width: 124px;
        height: 114px;
      }
    }
    &.step5 {
      .info .step-number {
        width: 130px;
        height: 114px;
      }
    }
  }
  .case1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1200px;
    position: relative;
    justify-content: space-between;
    margin-top: 50px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 33px;
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
    }
    .item-1 {
      width: 466px;
      height: 222px;
      background: #d8d8d8;
    }
    .item-2 {
      width: 708px;
      height: 222px;
      background: #d8d8d8;
    }
    .item-3 {
      width: 466px;
      height: 433px;
      background: #d8d8d8;
    }
    .item-4 {
      width: 343px;
      height: 183px;
      background: #d8d8d8;
    }
    .item-5 {
      width: 708px;
      height: 225px;
      background: #d8d8d8;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .case2 {
    display: flex;
    margin-top: 50px;
    padding-bottom: 100px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 280px;
      & + .item {
        margin-left: 40px;
      }
      .cover {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 180px;
        background: rgba(1, 26, 148, 0.3);
        position: relative;
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
        .item-title {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          line-height: 33px;
        }
      }
      .item-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        font-size: 20px;
        font-weight: bold;
        color: #222222;
        line-height: 33px;
        width: 200px;
        text-align: center;
        margin: 30px 0;
      }
    }
  }
}
</style>
