<template>
  <div class="company-progress-container">
    <div class="company-progress-wrap">
      <div class="progress-title">发展历程</div>
      <div class="progress-list">
        <div
          class="progress-item"
          v-for="(item, index) in progressData"
          :key="index"
        >
          <div class="progress-time">
            <div class="progress-line"></div>
            <span>{{ item.time }}</span>
          </div>
          <div class="progress-events">
            <span class="event-item" v-for="(e, i) in item.events" :key="i">
              {{ e }}
            </span>
          </div>
        </div>
      </div>
      <div class="progress-timeline"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const progressData = [
      {
        time: "2019年12月",
        events: ["中石油提出能量站概念"],
      },
      {
        time: "2020年5月",
        events: ["浙江昆仑大数据信息技术有限公司成立"],
      },
      {
        time: "2020年7月",
        events: ["能量站APP V1.0上线"],
      },
      {
        time: "2020年12月",
        events: ["能量站小程序上线", "能量站首家线下门店（嵊州吾悦店）开业"],
      },
      {
        time: "2021年1月",
        events: ["能量站首场年货节开启", "能量站企业账户上线"],
      },
      {
        time: "2021年3月",
        events: ["能量站首家品牌联名店（绍兴华茅联名店）开业"],
      },
      {
        time: "2021年4月",
        events: ["能量站首家标杆旗舰店（临安店）开业"],
      },
      {
        time: "2021年5月",
        events: ["能量站17能量日启动", "能量站APP V2.0上线"],
      },
      {
        time: "2021年7月",
        events: ["杭燃服务+能量站（朝晖店）开业", "能量站一周年——717能量节"],
      },
    ];
    return { progressData };
  },
};
</script>
<style lang="scss" scope>
.company-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #175499;
  width: 100%;
  padding-bottom: 24px;
  & > div {
    padding: 50px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .progress-title {
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
      line-height: 56px;
    }
    .progress-list {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 90px 100px 70px;
      max-width: 1240px;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 100%;
        height: 12px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        background: rgba(255, 255, 255, 1);
        height: 14px;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 0;
        background: rgba(255, 255, 255, 0.5);
        scrollbar-shadow-color: red;
      }
      .progress-item {
        flex-shrink: 0;
        width: 250px;
        .progress-time {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > span {
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            line-height: 33px;
            margin-left: 30px;
          }
          .progress-line {
            width: 20px;
            height: 133px;
            position: relative;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.7) 100%
            );
            &:after {
              width: 20px;
              height: 20px;
              background: #ffffff;
              content: " ";
              position: absolute;
              bottom: -20px;
              left: 0;
              right: 0;
            }
          }
        }
        .progress-events {
          display: flex;
          flex-direction: column;
          padding-top: 40px;
          padding-right: 20px;
          .event-item {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
    .progress-timeline {
      position: absolute;
      top: 348px;
      height: 3px;
      width: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.7) 30%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0.7) 70%,
        rgba(255, 255, 255, 0.3) 100%
      );
    }
  }
}
</style>
