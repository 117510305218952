<template>
  <div class="company-dynamic-container">
    <div class="title">企业动态</div>
    <div class="content">
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in dynamics"
          :key="index"
          @click="clickCard(item)"
        >
          <img class="item-img" :src="item.image" />
          <div class="item-info">
            <span class="item-title">{{ item.title }}</span>
            <span class="item-time">{{ item.time }}</span>
          </div>
        </div>
      </div>
      <img class="bg-img" src="@/assets/dynamic-bg.png" />
      <!-- <div class="button list-button">更多新闻</div> -->
    </div>
  </div>
  <div class="contact-us-container">
    <img class="bg-img" src="@/assets/contact-us-bg.png" />
    <span class="title">联系我们，获取尊享服务</span>
    <span class="desc">专业的产品介绍，一对一客户服务</span>
    <div class="button" @click="navigateTo('Cooperation')">联系我们</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dynamics: [
        {
          image: require("@/assets/dynamic20220109.png"),
          title: "凝心聚力 砥砺前行---昆仑能量站召开2021年度工作总结暨2022年度目标计划汇报会",
          time: "2022-01-09",
          url: "https://mp.weixin.qq.com/s?__biz=Mzg3ODMxNzU0MA==&mid=2247499972&idx=1&sn=2069027a2a45e9e487c97f23504db2f0&chksm=cf171a4ff86093597f02443d640931f9aa14956bf0690c49331376292c4e96fd4ce724636db6#rd",
        },
        // {
        //   image: require("@/assets/dynamic20210901.png"),
        //   title:
        //     "昆仑能量站与修正海南清修未来药房签约战略合作，提高市民购药便捷度",
        //   time: "2021-08-18",
        //   url: "https://mp.weixin.qq.com/s/AC_-CDuaOTwQcpsCgYoqgg",
        // },
        {
          image: require("@/assets/dynamic20210717.png"),
          title:
            "今日昆仑能量站朝晖店正式开业！杭州市区也有一站式综合服务体验店啦！",
          time: "2021-7-13",
          url:
            "https://mp.weixin.qq.com/s?__biz=Mzg3ODMxNzU0MA==&mid=2247489368&idx=1&sn=2b9a0261d7ca51f14ffdfe671258b72c&chksm=cf14cdd3f86344c58cce855fc4c7fb397207ed007d4b24a98b47085723f5485bc0df96bcda5f&token=1254736837&lang=zh_CN#rd",
        },
        // {
        //   image: require("@/assets/dynamic20210621.png"),
        //   title: "昆仑能量站组织开展党史学习参观活动，重温党的百年光辉历程",
        //   time: "2021-6-21",
        //   url:
        //     "https://mp.weixin.qq.com/s?__biz=Mzg3ODMxNzU0MA==&mid=2247489148&idx=1&sn=617df63932b818e327703708589632ee&chksm=cf14ccf7f86345e1f39084927dba4470b929a7e63ccb484a6f45c0c322051d8856f5b78cf047&token=225126659&lang=zh_CN#rd",
        // },
        {
          image: require("@/assets/dynamic20210613.png"),
          title: "中国石油能量站与北大荒集团达成战略合作，携手助力品质消费升级",
          time: "2021-6-13",
          url:
            "https://mp.weixin.qq.com/s?__biz=Mzg3ODMxNzU0MA==&mid=2247488699&idx=1&sn=9a09fb09598e7512e234ae2141924005&chksm=cf14ce30f8634726c981d1c321c3ca0e94a48f0d75a30c1266ec72a84cfc21c6ff2d35e8d99b&token=923483190&lang=zh_CN#rd",
        },
        // {
        //   image: require("@/assets/dynamic01.png"),
        //   title: "5G“能量站”再扩疆！全国标杆旗舰店开业啦！",
        //   time: "2021-04-30",
        //   url: "https://www.thepaper.cn/newsDetail_forward_12481209",
        // },
        // {
        //   image: require("@/assets/dynamic02.png"),
        //   title: "昆仑“能量站”绍兴店开业 改善居民消费体验 打造便民生活",
        //   time: "2021-03-31",
        //   url: "https://finance.ifeng.com/c/853gMxoW5KI",
        // },
        // {
        //   image: require("@/assets/dynamic03.png"),
        //   title:
        //     "昆仑能源非气业务再发力 “能量站”应用5G技术 打造社区生活服务“新样板”",
        //   time: "2020-12-25",
        //   url: "http://www.xinhuanet.com/money/2020-12/25/c_1210947518.htm",
        // },
      ],
    };
  },
  methods: {
    clickCard(data) {
      window.open(data?.url);
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
<style lang="scss">
.company-dynamic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }
  .content {
    width: 100%;
    padding-top: 90px;
    margin-top: 24px;
    position: relative;
    .bg-img {
      height: 360px;
      width: 100%;
    }
    .button {
      position: absolute;
      bottom: 63px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 150px;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #ffffff;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 25px;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 1200px;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;

      .item {
        width: 360px;
        border-radius: 8px;
        overflow: hidden;
        &:hover {
          cursor: pointer;
        }
        .item-img {
          width: 100%;
          height: 170px;
          background: #666666;
          display: flex;
          object-fit: cover;
        }
        .item-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: #ffffff;
          padding: 12px 15px 20px;
          height: 83px;
          .item-title {
            font-size: 16px;
            color: #222222;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-time {
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
  }
}
.contact-us-container {
  width: 100%;
  height: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bg-img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #222222;
    line-height: 42px;
  }
  .desc {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
    margin-top: 25px;
  }
  .button {
    width: 121px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #1858a3;
    font-size: 16px;
    font-weight: 500;
    color: #1858a3;
    line-height: 22px;
    background: transparent;
    margin-top: 44px;
  }
}
</style>
