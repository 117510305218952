<template>
  <div class="company-store-map-container">
    <div id="storeMap" class="map"></div>
    <div class="info">
      <div class="info-title">能量站门店</div>
      <div class="info-select">
        <el-select
          class="select"
          v-model="provinceId"
          placeholder="请选择"
          @change="changeProvince"
        >
          <el-option
            v-for="(item, index) in areas"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select
          class="select"
          v-model="cityId"
          placeholder="请选择"
          @change="changeCity"
        >
          <el-option
            v-for="(item, index) in citys"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="info-list">
        <div class="info-item" v-for="(item, index) in storeData" :key="index">
          <div class="item-title" @click="locationStore(index)">
            {{ item.name }}
          </div>
          <div class="item-text">{{ item.address }}</div>
          <div class="item-text">电话：{{ item.phone }}</div>
          <div class="item-text">正常营业时间：{{ item.time1 }}</div>
          <div class="item-text">周末及法定节假日：{{ item.time1 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyInfoWindow from "@/util/MyInfoWindow";
const storeData = [
  {
    id: 1,
    provinceId: 1,
    cityId: 2,
    name: "能量站(临安店)",
    address: "浙江省杭州市临安区城中街513号",
    time1: "9:00-21:00",
    phone: "19157921060",
    location: "30.228136,119.714496",
  },
  {
    id: 2,
    provinceId: 1,
    cityId: 3,
    name: "能量站(华茅联名店)",
    address: "浙江省绍兴市越城区东池路68号",
    time1: "9:00-19:30",
    phone: "18105853997",
    location: "30.005605,120.593313",
  },
  {
    id: 3,
    provinceId: 1,
    cityId: 3,
    name: "能量站(嵊州吾悦店)",
    address: "浙江省嵊州市三江街道桥南路666号江湾一号澜泊湾141号(吾悦广场旁)",
    time1: "9:00-21:00",
    phone: "13388493044",
    location: "29.572019,120.819458",
  },
  {
    id: 4,
    provinceId: 1,
    cityId: 2,
    name: "昆仑能量站(朝晖店)",
    address: "浙江省杭州市拱墅区河东路191号",
    time1: "9:00-21:00",
    phone: "13858112683",
    location: "30.28797,120.159675",
  },
];
export default {
  data() {
    const areas = [
      {
        id: 1,
        name: "浙江省",
        children: [
          {
            id: 2,
            name: "杭州市",
          },
          {
            id: 3,
            name: "绍兴市",
          },
        ],
      },
    ];
    return {
      storeData,
      map: undefined,
      infoWindows: [],
      areas,
      provinceId: 1,
      citys: [],
      cityId: undefined,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.map?.destroy();
      const storeData = this.storeData;
      const { location } = storeData[0];
      const center = new TMap.LatLng(
        location.split(",")[0],
        location.split(",")[1]
      );
      const map = new TMap.Map(document.getElementById("storeMap"), {
        center,
        zoom: 17.2,
        pitch: 43.5,
        rotation: 45,
      });
      this.map = map;
      const marker = new TMap.MultiMarker({
        id: "marker-layer",
        map,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 24,
            height: 35,
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: storeData.map(({ location }, index) => ({
          id: `marker${index}`,
          styleId: "marker",
          position: new TMap.LatLng(
            location.split(",")[0],
            location.split(",")[1]
          ),
          properties: { index },
        })),
      });
      const infoWindows = this.storeData.map(({ location }, index) => ({
        id: `marker${index}`,
        infoWindow: new MyInfoWindow({
          map,
          position: new TMap.LatLng(
            location.split(",")[0],
            location.split(",")[1]
          ),
          content: this.generateContent(index),
        }),
      }));
      this.infoWindows = infoWindows;
      marker.on("click", function(event) {
        const {
          geometry: { id },
        } = event;
        const { infoWindow } = infoWindows.find((item) => item.id === id);
        infoWindow.toggle();
      });
      this.citys = this.areas.find(
        (item) => item.id === this.provinceId
      ).children;
    },
    generateContent(index) {
      const store = this.storeData[index];
      return `
        <div class="map-marker">
          <div class="top">
            <span>${store.name}</span>
            <span>生活服务</span>
          </div>
          <div class="bottom">
            <div class="address">地址：${store.address}</div>
            <!--
            <div class="photo-info">
              <div class="photo-title">门店快看</div>
              <div class="photos">
                <img />
                <img />
                <img />
              </div>
            </div>
            -->
          </div>
        </div>
      `;
    },
    locationStore(index) {
      const { location } = this.storeData[index];
      this.map.setCenter(
        new TMap.LatLng(location.split(",")[0], location.split(",")[1])
      );
      const { infoWindow } = this.infoWindows[index];
      infoWindow.open();
    },
    changeProvince() {},
    changeCity(cityId) {
      this.storeData = storeData.filter((item) => item.cityId === cityId);
      this.init();
    },
  },
};
</script>
<style lang="scss">
.company-store-map-container {
  display: flex;
  width: 100%;
  height: 450px;
  .map {
    position: relative;
    width: 820px;
    height: 450px;
  }
  .info {
    flex: 1;
    flex-direction: column;
    background: #3385ff;
    padding: 24px 40px 0;
    .info-title {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      line-height: 42px;
    }
    .info-select {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 10px;
      .select {
        width: 206px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        & + .select {
          margin-left: 30px;
        }
        .select-trigger,
        .el-input,
        .el-input__inner {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 25px;
          height: 100%;
          background: transparent;
        }
      }
    }
    .info-list {
      display: flex;
      flex-direction: column;
      height: 300px;
      overflow: scroll;
      .info-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 27px;
        border-bottom: 1px solid #ffffff;
        &:last-child {
          border-bottom: none;
        }
        .item-title {
          font-size: 20px;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;
          margin: 20px 0 12px;
          &:hover {
            cursor: pointer;
          }
        }
        .item-text {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          & + .item-text {
            margin-top: 12px;
          }
        }
      }
    }
  }
  .map-marker {
    width: 219px;
    div.top {
      background: #3385ff;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 12px;
      span {
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
        &:first-child {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
    div.bottom {
      background: #ffffff;
      padding: 8px 12px 12px;
      border-radius: 0px 0px 8px 8px;
      .address {
        font-size: 8px;
        font-weight: 400;
        color: #555555;
        line-height: 13px;
      }
      .photo-info {
        display: flex;
        flex-direction: column;
        .photo-title {
          font-size: 8px;
          font-weight: bold;
          color: #555555;
          line-height: 11px;
          padding: 12px 0 7px;
          border-bottom: 1px solid #e8e8e8;
        }
        .photos {
          display: flex;
          justify-content: space-between;
          padding: 9px 0;
          & > img {
            width: 60px;
            height: 45px;
            border-radius: 4px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
