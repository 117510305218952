<template>
  <Header @getHeight="getHeaderHeight" />
  <div class="cooperation-container" :style="{ paddingTop: headerHeight / 198 + 'rem' }">
    <div class="win-win">
      <span class="title">合作共赢</span>
      <div class="info">
        <div class="img">
          <img src="@/assets/win-win.png" />
        </div>
        <span class="desc">
          能量站坚持秉承国家十四五规划以及二〇三五年远景目标精神，与全国各燃气公司合作，依托共享收益（昆仑银行为唯一结算体系）、共享资源、共享市场的理念，构建了标准化门店开设、标准化空间布局、标准化服务选品、标准化人性服务为主要核心内容的协同体系，进而充分实践中国石油“清洁能源供应的主力军，美丽中国建设的先锋队，人民美好生活的服务员”的愿景。现我们诚邀合作伙伴，共同为广大群众带来全新、全方位的综合性服务。
        </span>
      </div>
    </div>
    <template v-for="(item, index) in cooperationData" :key="index">
      <!-- <div class="title">{{ item.title }}</div> -->
      <div class="card">
        <div>
          <img class="image" :src="item.image" />
          <div class="info">
            <span class="info-title">{{ item.title }}</span>
            <span class="info-desc">{{ item.desc }}</span>
            <span v-if="!!item.require" v-html="item.require"></span>
            <span>{{ item.word }}</span>
            <span>{{ item.user }}<br />{{ item.email }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import OfflineStore from "@/components/OfflineStore.vue";
import BusinessEnter from "@/components/BusinessEnter.vue";
export default {
  name: "Cooperation",
  components: {
    Header,
    Footer,
    OfflineStore,
    BusinessEnter,
  },
  data() {
    const cooperationData = [
      {
        title: "门店合作",
        desc:
          "能量站将在十四五规划期间共计完成超过3万家线下实体体验店建设，以长三角为核心，辐射全国，打造能量站全国服务网络。深入下沉社区服务业务，全面囊括居民生活的衣食住行医疗，为居民生活、社交活动提供了一种全新体验。",
        word: "如果您有意向与我们进行合作，电话联系我们。",
        user: "王亚东：15238081752",
        image: require("@/assets/cooperation1.png"),
        email: "WTwangyadong@nengliangwang.com"
      },
      {
        title: "供应商合作",
        desc:
          "能量站欢迎优质供应商加入，为居民提供高品质商品，满足他们对美好生活的向往，携手共建品质消费生态圈。",
        require: `我们需要您有：<br/>运营资质：合规运营，拥有完善的企业运营资质<br/>产品资质：合格的产品检验资质，保证产品品质<br/>诚信履约：高效诚信，切实履行所签署的入驻协议`,
        word: "如果您有意向，可电话联系我们。",
        user: "徐溱：13761052761\n徐溱：xuzhen@nengliangwang.com",
        image: require("@/assets/cooperation2.png"),
        email: "陈少华：13609733509\n陈少华：chenshaohua@nengliangwang.com"
      },
    ];
    return {
      headerHeight: 0,
      cooperationData,
    };
  },
  methods: {
    getHeaderHeight(value) {
      this.headerHeight = value;
    },
  },
};
</script>

<style lang="scss">
.cooperation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;

  .win-win {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;

    .title {
      font-size: 38px;
      font-weight: 600;
      color: #0a2349;
      line-height: 53px;
      margin-top: 120px;
    }

    .info {
      width: 1200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 50px;

      .img {
        width: 530px;
        height: 313px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .desc {
        flex: 1;
        font-size: 20px;
        font-weight: 400;
        color: #0a2349;
        line-height: 35px;
        margin-left: 67px;
      }
    }
  }

  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }

  .card {
    width: 1200px;
    // height: 378px;
    padding: 12px 0;
    background: #f8fafc;

    &+.card {
      margin-top: 40px;
    }

    &>div {
      display: flex;
      // align-items: center;
      padding: 40px;

      .image {
        width: 450px;
        height: 298px;
        background: #d8d8d8;
        object-fit: cover;
      }

      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;
        color: #0a2349;
        line-height: 22px;
        margin-left: 40px;

        &>span {
          white-space: pre-wrap;
        }

        &>span+span {
          margin-top: 20px;
        }

        .info-title {
          font-size: 24px;
          color: #222222;
          line-height: 33px;
        }

        .info-desc {
          font-size: 14px;
          color: #666666;
          line-height: 25px;
          margin: 12px 0 0px;
        }
      }
    }
  }
}
</style>
