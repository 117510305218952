<template>
  <Header @getHeight="getHeaderHeight" />
  <div
    class="about-container"
    :style="{ paddingTop: headerHeight / 198 + 'rem' }"
  >
    <div class="title">企业宣传片</div>
    <div class="video">
      <VideoPlayer />
    </div>
    <CompanyIntro />
    <CompanyProgress />
    <CompanyPhoto />
    <CompanyJoin />
    <CompanyStoreMap />
  </div>
  <Footer />
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import CompanyIntro from "@/components/CompanyIntro.vue";
import CompanyProgress from "@/components/CompanyProgress.vue";
import CompanyPhoto from "@/components/CompanyPhoto.vue";
import CompanyJoin from "@/components/CompanyJoin.vue";
import CompanyStoreMap from "@/components/CompanyStoreMap.vue";
export default {
  name: "About",
  components: {
    Header,
    Footer,
    VideoPlayer,
    CompanyIntro,
    CompanyProgress,
    CompanyPhoto,
    CompanyJoin,
    CompanyStoreMap,
  },
  data() {
    return {
      headerHeight: 0,
    };
  },
  methods: {
    getHeaderHeight(value) {
      this.headerHeight = value;
    },
  },
};
</script>
<style lang="scss">
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }
  .desc {
    font-size: 24px;
    font-weight: bold;
    color: #0a2349;
    margin-top: 40px;
  }
  .video {
    margin-top: 50px;
    width: 1200px;
    height: 675px;
  }
}
</style>
