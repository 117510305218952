<template>
  <div class="custom-video " id="custom-video"></div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    new Aliplayer({
      id: "custom-video",
      width: "100%",
      height: "100%",
      autoplay: true,
      source: "https://qnimg.didi315.com/video/video-intro2.mp4",
    });
  },
};
</script>
<style lang="scss">
.custom-video {
  width: 100%;
  height: 100%;
  background: #000000;
}
</style>
