export default function MyInfoWindow(options) {
  TMap.DOMOverlay.call(this, options);
}

MyInfoWindow.prototype = new TMap.DOMOverlay();

MyInfoWindow.prototype.mydom = undefined;

MyInfoWindow.prototype.onInit = function(options) {
  this.position = options.position;
  this.content = options.content;
};

MyInfoWindow.prototype.createDOM = function() {
  const mydom = document.createElement("div");
  mydom.style.cssText = "position:absolute;top:0px;left:30px;display:none";
  mydom.innerHTML = this.content;
  this.mydom = mydom;
  return mydom;
};

MyInfoWindow.prototype.updateDOM = function() {
  if (!this.map) {
    return;
  }
  let pixel = this.map.projectToContainer(this.position);
  let left = pixel.getX() + "px";
  let top = pixel.getY() - this.dom.clientHeight / 2 + "px";
  this.dom.style.transform = `translate3d(${left}, ${top}, 0px)`;
};

MyInfoWindow.prototype.setContent = function(content) {
  this.mydom.innerHTML = content;
};

MyInfoWindow.prototype.setPosition = function(position) {
  this.position = position;
};

MyInfoWindow.prototype.open = function() {
  this.mydom.style.display = "flex";
};

MyInfoWindow.prototype.toggle = function() {
  const display = this.mydom.style.display;
  if (display === "none") {
    this.mydom.style.display = "flex";
  } else {
    this.mydom.style.display = "none";
  }
};
