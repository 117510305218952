<template>
  <div class="business-enter-container">
    <div class="title">100+供应商品牌已入驻</div>
    <div class="button" @click="onApply('supplier')">供应商入驻</div>
    <div class="scroll-wrap">
      <div class="scroll-list goscroll1">
        <div
          class="scroll-item"
          v-for="(item, index) in storeData"
          :key="index"
        >
          <img class="img" :src="item" />
        </div>
      </div>
      <div class="scroll-list goscroll2">
        <div
          class="scroll-item"
          v-for="(item, index) in storeData"
          :key="index"
        >
          <img class="img" :src="item" />
        </div>
      </div>
    </div>
    <div class="sub-title">供应商入驻流程</div>
    <div class="process">
      <template v-for="(item, index) in enterProcess" :key="index">
        <div class="item">
          <div class="item-index">{{ item.index }}</div>
          <span class="item-title">{{ item.title }}</span>
          <span class="item-desc">{{ item.desc }}</span>
        </div>
        <img
          class="arrow"
          v-if="index !== enterProcess.length - 1"
          src="@/assets/arrow.png"
        />
      </template>
    </div>
    <div class="title">100+企业入驻</div>
    <div class="button" @click="onApply('company')">企业入驻</div>
    <div class="scroll-wrap">
      <div class="scroll-list goscroll3">
        <div
          class="scroll-item"
          v-for="(item, index) in companyData"
          :key="index"
        >
          <img class="img" :src="item" />
        </div>
      </div>
      <!-- <div class="scroll-list goscroll4">
        <div
          class="scroll-item"
          v-for="(item, index) in companyData"
          :key="index"
        >
          <img class="img" :src="item" />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const enterProcess = [
      {
        index: "01",
        title: "供应商资质审核",
        desc: "营业执照、相关安全证书等",
      },
      { index: "02", title: "供应商报价", desc: "提供商品和价格清单" },
      { index: "03", title: "签署合同", desc: "确定合作模式签署合同" },
      {
        index: "04",
        title: "商品上架",
        desc: "供应商上传商品信息能量站审核上架商品",
      },
    ];
    let storeData = Array(23)
      .fill("")
      .map((_, index) => require(`@/assets/store/logo${index + 1}.png`));
    storeData = storeData.concat(storeData);
    let companyData = Array(10)
      .fill("")
      .map((_, index) => require(`@/assets/company/logo${index + 1}.png`));
    companyData = companyData.concat(companyData).concat(companyData);
    return { enterProcess, storeData, companyData };
  },
  mounted() {},
  methods: {
    onApply(type) {
      this.$emit("apply", type);
    },
    getCssRule() {
      let animation = {};
      document.styleSheets?.forEach((element) => {
        try {
          element?.rules?.forEach((item, index) => {
            if (
              typeof item === "object" &&
              item.name === "goscroll1" &&
              item.type === 7
            ) {
              animation.cssRule = item;
              animation.styleSheet = element;
              animation.index = index;
            }
          });
        } catch (error) {}
      });
      if (!!animation) {
        animation.styleSheet.deleteRule(animation.index);
        animation.styleSheet.insertRule(
          "@keyframes goscroll1{0%{transform: translateX(0);} 100%{transform: translateX(-1400px);}",
          animation.index
        );
      }
    },
  },
};
</script>
<style lang="scss" scope>
.business-enter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }
  .button {
    width: 200px;
    height: 58px;
    border-radius: 8px;
    border: 2px solid #0d57a3;
    font-size: 20px;
    font-weight: 600;
    color: #0e58a4;
    line-height: 28px;
    margin: 30px 0 50px;
  }
  .sub-title {
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    line-height: 33px;
    margin-top: 50px;
  }
  .process {
    display: flex;
    margin-top: 80px;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow {
      width: 27px;
      height: 30px;
    }
    .item {
      display: flex;
      flex-direction: column;
      width: 211px;
      height: 168px;
      background: #ffffff;
      box-shadow: 2px 5px 8px 0px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: 4px solid #ffffff;
      padding: 0 20px;
      position: relative;
      & + .item {
        margin-left: 30px;
      }
      .item-index {
        position: absolute;
        top: -28px;
        left: 0;
        right: 0;
        margin: auto;
        width: 56px;
        height: 56px;
        background: linear-gradient(140deg, #3784f0 0%, #619ff2 100%);
        border-radius: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
      }
      .item-title {
        font-size: 24px;
        font-weight: bold;
        color: #222222;
        line-height: 33px;
        margin-top: 42px;
      }
      .item-desc {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        line-height: 22px;
        margin-top: 18px;
      }
    }
  }
  .scroll-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .scroll-list {
      display: flex;
      flex-direction: row;
      width: 1200px;
      &.goscroll1 {
        animation: goscroll1 30s linear 0s infinite normal;
      }
      &.goscroll2 {
        animation: goscroll2 30s linear 0s infinite normal;
      }
      &.goscroll3 {
        animation: goscroll1 30s linear 0s infinite normal;
      }
      &.goscroll4 {
        animation: goscroll2 30s linear 0s infinite normal;
      }
      .scroll-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: #ffffff;
        width: 173px;
        height: 200px;
        background: #ffffff;
        margin: 20px 8px;
        box-shadow: 3px 7px 11px 0px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
@keyframes goscroll1 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-3000px);
  }
}
@keyframes goscroll2 {
  0% {
    transform: translateX(-3000px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes goscroll3 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-3000px);
  }
}
@keyframes goscroll4 {
  0% {
    transform: translateX(-3000px);
  }
  to {
    transform: translateX(0);
  }
}
</style>
