<template>
  <div class="company-intro-container">
    <div class="title">公司介绍</div>
    <div class="desc">
      “线上+线下”综合性服务店 ；能量站为您提供更便民、利民的综合性服务
    </div>
    <div class="tabs">
      <div
        class="item"
        :class="{ active: index === tabIndex }"
        v-for="(item, index) in intros"
        :key="index"
        @click="clickTab(index)"
      >
        <span class="item-name">{{ item.name }}</span>
        <div v-if="index === tabIndex" class="item-line" />
      </div>
    </div>
    <div class="tab-content">
      <div class="circle">
        <img
          class="circle-bg"
          :style="{ transform: 'rotate(' + circleData.rotate + 'deg)' }"
          src="@/assets/intro-circle.png"
        />
        <img class="circle-selected" src="@/assets/intro-circle-select.png" />
        <img class="circle-center" src="@/assets/intro-circle-center.png" />
        <div
          class="text"
          :class="['text' + (index + 1)]"
          v-for="(item, index) in circleData.intros"
          :key="index"
          @click="clickCircle(item.name)"
        >
          <img :src="index === 0 ? item.img : item.imgSelect" />
          <!-- <span v-html="item.name"></span> -->
        </div>
      </div>
      <div class="content">
        <span class="content-name">{{ intros[tabIndex].name }}</span>
        <div class="content-desc" v-html="intros[tabIndex].desc"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const intros = [
      {
        name: "能量站介绍",
        desc:
          "昆仑能量站是浙江昆仑大数据信息技术有限公司应用5G技术打造的“线上+线下”模式综合性服务店，通过集燃气缴费、医疗健康、生活服务、社区电商为一体的线下体验中心与线上APP、小程序相结合，并充分利用大数据、AI等前沿技术，为广大群众带来全新、全方位的综合性服务。",
        img: require("@/assets/intro-img2.png"),
        imgSelect: require("@/assets/intro-img2-select.png"),
      },
      {
        name: "团队介绍",
        desc:
          "浙江昆仑大数据信息技术有限公司集结了一支精英团队，建成了以技术研发、平台运营、品牌营销为核心等的人才队伍，核心团队均来自腾讯、百度、阿里等国内知名行业。团队拥有强有力的市场资源整合能力和研发实战能力，能够快速构建商业模式体系。<br/>公司获中茵控股集团有限公司和江苏衢州大数据信息有限公司等多家上市公司战略投资。",
        img: require("@/assets/intro-img1.png"),
        imgSelect: require("@/assets/intro-img1-select.png"),
      },
      {
        name: "价值观及理念",
        desc:
          "核心宗旨：全心全意为人民提供高质量服务<br/>价值观：您的需求就是我们努力的方向",
        img: require("@/assets/intro-img3.png"),
        imgSelect: require("@/assets/intro-img3-select.png"),
      },
    ];
    const circleData = {
      intros,
      rotate: 0,
    };
    return {
      intros,
      tabIndex: 0,
      circleData,
    };
  },
  watch: {
    tabIndex(newValue, oldValue) {
      if (newValue > oldValue) {
        // 顺时针旋转
        this.circleData.rotate += 120 * (newValue - oldValue);
      } else {
        // 逆时针旋转
        this.circleData.rotate -= 120 * (oldValue - newValue);
      }
      const intros = this.intros;
      const newIntros = [];
      newIntros.push(intros[newValue]);
      newIntros.push(intros[newValue + 1 > 2 ? newValue - 2 : newValue + 1]);
      newIntros.push(intros[newValue + 2 > 2 ? newValue - 1 : newValue + 2]);
      this.circleData.intros = newIntros;
    },
  },
  methods: {
    clickTab(index) {
      this.tabIndex = index;
    },
    clickCircle(name) {
      this.tabIndex = this.intros.findIndex((item) => item.name === name);
    },
  },
};
</script>
<style lang="scss">
.company-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 120px;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
  }
  .desc {
    font-size: 18px;
    font-weight: bold;
    color: #0a2349;
    line-height: 25px;
    margin-top: 30px;
  }
  .tabs {
    display: flex;
    align-items: center;
    padding: 50px 0 70px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 15px;
      position: relative;
      &:hover {
        cursor: pointer;
      }
      & + .item {
        margin-left: 100px;
      }
      &.active {
        .item-name {
          color: #175499;
        }
        .item-line {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 76px;
          height: 4px;
          margin-top: 12px;
          background: #175499;
        }
      }
      .item-name {
        font-size: 24px;
        font-weight: 600;
        color: #175499;
        line-height: 33px;
      }
    }
  }
  .tab-content {
    display: flex;
    align-items: center;
    width: 1200px;
    .circle {
      display: flex;
      width: 360px;
      height: 360px;
      position: relative;
      &-bg {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
      }
      &-selected {
        position: absolute;
        right: -14px;
        margin: 12px auto;
        width: 142px;
        height: 335px;
      }
      &-center {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        width: 178px;
        height: 178px;
      }
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
        & > span {
          width: 60px;
        }
        & > img {
          width: 40px;
          height: 40px;
          object-fit: fill;
        }
        &.text1 {
          right: -24px;
          width: 130px;
          height: 100%;
          background: transparent;
        }
        &.text2 {
          top: 0;
          background: transparent;
          height: 130px;
          width: 210px;
          color: #0a7dff;
        }
        &.text3 {
          bottom: 0;
          background: transparent;
          height: 130px;
          width: 200px;
          color: #0a7dff;
        }
      }
    }
    .content {
      flex: 1;
      width: 500px;
      height: 305px;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.4) 0%,
        #dde9f8 100%
      );
      position: relative;
      left: -60px;
      padding: 0 140px;
      z-index: -1;
      display: flex;
      flex-direction: column;
      &-name {
        font-size: 24px;
        font-weight: 600;
        color: #0a2349;
        line-height: 33px;
        margin-top: 56px;
      }
      &-desc {
        font-size: 14px;
        font-weight: 400;
        color: #0a2349;
        line-height: 25px;
        margin-top: 20px;
      }
    }
  }
}
</style>
