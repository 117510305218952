<template>
  <div class="service-weapp-container">
    <!-- <div class="title">让购物从此变得有趣  更精准体贴的生活站</div> -->
    <!-- <div class="desc">
      首批合作用户为市值/政治级别/战略定位对等中国石油的一类账户企业
    </div> -->
    <div class="decorate">
      <div>
        <img class="img" src="@/assets/weapp-mobile1.png" />
        <div class="info">
          <span class="info-title">让购物从此变得有趣 更精准体贴的生活站</span>
          <span class="info-desc">
            活动会场、现金签到、红包礼券，更多玩法不断解锁
            <!-- 微信、QQ、微博、小程序，把握社交网络每一份流量 -->
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="title">能量站业务输出</div>
    <div class="desc">能量站所有合作体系优先合作一类账户</div> -->
    <div
      class="service"
      :class="{ reverse: index % 2 === 1 }"
      v-for="(item, index) in serviceData"
      :key="index"
    >
      <div>
        <img class="img" :src="item.image" />
        <div class="blank" />
        <div class="info">
          <span class="info-title">{{ item.title }}</span>
          <span class="info-desc" v-html="item.desc"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const serviceData = [
      {
        image: require("@/assets/weapp-img1.png"),
        title: "海量商品",
        desc:
          "海量商品 精挑细选 超值价格 高质品位<br/><!--活动会场、现金签到、红包礼券，更多玩法不断解锁<br/>-->1元秒杀、拼团免单、多件多折、低价好货、等你挑选",
      },
      {
        image: require("@/assets/weapp-img2.png"),
        title: "医疗服务",
        desc:
          "好大夫联合义诊、联合会诊，一键预约，专业医生极速回复，你的家庭私人医生，健康有保障",
      },
      {
        image: require("@/assets/weapp-img3.png"),
        title: "中国石油官方缴费口",
        desc: "足不出户  轻松缴费  便民服务 尽享一键云缴费",
      },
      {
        image: require("@/assets/weapp-img4.png"),
        title: "能量家族",
        desc: "挑选优质商品、分享达人，一键返佣，轻松创业",
      },
      {
        image: require("@/assets/weapp-img5.png"),
        title: "家政服务",
        desc:
          "优质服务 严格审核<br/>提供保洁、清洗、全屋日常清洁套餐，家里光亮如新，不再担忧。",
      },
      {
        image: require("@/assets/weapp-img6.png"),
        title: "企业服务",
        desc: "提供企业专属会员服务，会员折扣，会员福利购，品质生活保障",
      },
      {
        image: require("@/assets/weapp-img7.png"),
        title: "黄金兑换",
        desc: "能量币兑换，线上黄金，商品，线下免费兑换氧舱，咖啡服务内容",
      },
    ];
    return { serviceData };
  },
};
</script>
<style lang="scss">
.service-weapp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }
  .desc {
    font-size: 18px;
    font-weight: 500;
    color: #0a2349;
    line-height: 25px;
    margin-top: 30px;
  }
  .decorate {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    margin-top: 50px;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1000px;
      padding: 20px 100px;
      .img {
        width: 250px;
        height: 488px;
      }
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 140px;
        .info-title {
          font-size: 26px;
          font-weight: bold;
          color: #222222;
          line-height: 42px;
        }
        .info-desc {
          font-size: 20px;
          font-weight: 500;
          color: #4c5875;
          line-height: 33px;
          margin-top: 40px;
        }
      }
    }
  }
  .service {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    &.reverse {
      background-color: #f9f9f9;
      & > div {
        flex-direction: row-reverse;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      width: 1200px;
      padding: 80px 0;
    }
    .img {
      width: 526px;
      height: 436px;
      :first-child {
        height: 420px;
      }
    }
    .blank {
      width: 40px;
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .info-title {
        font-size: 26px;
        font-weight: 600;
        color: #0a2349;
        line-height: 53px;
      }
      .info-desc {
        font-size: 20px;
        font-weight: 500;
        color: #0a2349;
        line-height: 44px;
        margin-top: 36px;
      }
    }
  }
}
</style>
