<template>
  <div class="footer-container">
    <div>
      <div class="top">
        <div class="info">
          <img class="logo" src="@/assets/logo2.png" />
          <span class="address">浙江省杭州市滨江区滨盛路1508号海亮大厦6楼</span>
          <span>电话：400-135-5577（工作日09:00-18:00）</span>
        </div>
        <div class="menu-link">
          <span class="title">关注我们</span>
          <div class="item">
            <div class="qrcode">
              <img src="@/assets/wxqrcode.png" />
            </div>
            <img src="@/assets/icon-wechat.png" />
            <span>微信官方公众号</span>
          </div>
          <div class="item">
            <div class="qrcode douyin">
              <img src="@/assets/dyqrcode.png" />
            </div>
            <img src="@/assets/icon-dy.png" />
            <span>抖音</span>
          </div>
        </div>
        <div class="menu-link">
          <span class="title">平台规则</span>
          <el-link class="item" :underline="false" href="https://qnimg.wosongjiu.com/document%2F85a1a43c-4073-4d1e-9d14-a15e09258d75.pdf" target="_blank">
            消费者保证金规则
          </el-link>
          <el-link class="item" :underline="false" href="https://qnimg.wosongjiu.com/document%2F0cdc0e0f-521e-4b07-a62c-e11cb95581cf.pdf" target="_blank">
            平台供应商发货管理规则
          </el-link>
  <el-link class="item" :underline="false" href="https://qnimg.wosongjiu.com/document%2F0cdc0e0f-521e-4b07-a62c-e11cb95581cf.pdf" target="_blank">
            昆仑能量站及关联上下游采购平台各类目资费规则
          </el-link>
      </div>
        <div class="related-link">
          <span class="title">能量站APP/小程序</span>
          <div class="list">
            <div class="item">
              <img src="@/assets/app_qrcode1.png" />
              <span>微信小程序</span>
            </div>
            <div class="item">
              <img src="@/assets/app_qrcode2.png" />
              <span>APP</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        © 2020- 2021 浙江昆仑大数据信息技术有限公司
        版权所有&nbsp;&nbsp;|&nbsp;&nbsp;
        <el-link :style="'font-size:14px;color:#ffffff;line-height:25px;'" :underline="false"
          href="https://beian.miit.gov.cn" target="_blank">浙ICP备2021026850号-1
        </el-link>
        &nbsp;&nbsp;
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomFooter",
  data() {
    return { visible: true };
  },
};
</script>
<style lang="scss">
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: #15346d;
  background: #1b2389;
  z-index: 9999;
  padding: 60px 0;
  width: 100%;

  &>div {
    width: 1200px;

    &>div.top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;
      border-bottom: 1px solid #979797;

      .info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;

        .logo {
          width: 186px;
          height: 47px;
        }

        span {
          margin: 24px 0 0;
        }
      }

      .menu-link {
        display: flex;
        flex-direction: column;
        width: 200px;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 24px;
        }

        .item {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          position: relative;
          justify-content: flex-start;

          &:hover {
            cursor: pointer;

            .qrcode {
              opacity: 1;
            }
          }

          &+.item {
            margin: 20px 0 0;
          }

          &>img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          .qrcode {
            position: absolute;
            left: -160px;
            width: 140px;
            height: 140px;
            background: #ffffff;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;

            &>img {
              width: 124px;
              height: 124px;
              object-fit: fill;
            }
          }

          .qrcode.douyin {
            top: -102px;
          }
        }

      }

      .related-link {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 29px;
        }

        .list {
          display: flex;
          align-items: center;

          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &+.item {
              margin: 0 0 0 30px;
            }

            img {
              width: 80px;
              height: 80px;
              background: #d8d8d8;
              border-radius: 8px;
              margin-bottom: 12px;
              overflow: hidden;
            }

            span {
              font-size: 12px;
              font-weight: bold;
              color: #ffffff;
              line-height: 17px;
            }
          }
        }
      }
    }

    &>div.bottom {
      font-size: 14px;
      color: #ffffff;
      line-height: 25px;
      padding-top: 40px;
    }
  }
}
</style>
