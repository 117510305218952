<template lang="">
  <Header @getHeight="getHeaderHeight" />
  <div
    class="service-container"
    :style="{ paddingTop: headerHeight / 144 + 'rem' }"
  >
    <router-view></router-view>
  </div>
  <Footer />
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Service",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      headerHeight: 0,
    };
  },
  methods: {
    getHeaderHeight(value) {
      this.headerHeight = value;
    },
  },
};
</script>
<style lang=""></style>
