<template>
  <div class="offline-store-container">
    <div class="title">线下能量站门店</div>
    <div class="button" @click="onApply('store')">门店合作</div>
    <Swiper
      class="swiper"
      :pagination="{
        clickable: true,
        type: 'custom',
        el: '.swiper-pagination',
        renderCustom: renderCustomPagination,
      }"
      :autoplay="{
        delay: 3000,
      }"
    >
      <SwiperSlide v-for="(item, index) in swiperData" :key="index">
        <div class="slide">
          <img class="img" :src="item.image" />
          <div class="bg">
            <img src="@/assets/swiper-bg.png" />
            <div class="info">
              <span>{{ item.title }}</span>
              <span>{{ item.address }}</span>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <div class="swiper-pagination"></div>
    </Swiper>
  </div>
</template>
<script>
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Pagination, Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const swiperData = [
      {
        image: require("@/assets/swiper-img1.png"),
        title: "昆仑能量站全国标杆旗舰店",
        address: "门店地址：浙江省杭州市临安区城中街513号",
      },
      {
        image: require("@/assets/swiper-img2.png"),
        title: "昆仑能量站华茅联名店",
        address: "门店地址：浙江省绍兴市越城区东池路68号",
      },
      {
        image: require("@/assets/swiper-img3.png"),
        title: "昆仑能量站(嵊州吾悦店)",
        address:
          "门店地址：嵊州市三江街道桥南路666号江湾一号澜泊湾141号(吾悦广场旁)",
      },
      {
        image: require("@/assets/swiper-img4.png"),
        title: "昆仑能量站（朝晖店）",
        address: "门店地址：浙江省杭州市拱墅区河东路191号",
      },
    ];
    return { swiperData };
  },
  methods: {
    onApply(type) {
      this.$emit("apply", type);
    },
    renderCustomPagination(swiper, current, total) {
      let paginationHtml = `<div class="pagination-wrap">`;
      for (let i = 1; i <= total; i++) {
        if (i <= current) {
          paginationHtml += `<div class="pagination pagination-active"></div>`;
        } else {
          paginationHtml += `<div class="pagination"></div>`;
        }
      }
      paginationHtml += "</div>";
      return paginationHtml;
    },
  },
};
</script>
<style lang="scss" scope>
.offline-store-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 0;

  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
  }

  .button {
    width: 200px;
    height: 58px;
    border-radius: 8px;
    border: 2px solid #0d57a3;
    font-size: 20px;
    font-weight: 600;
    color: #0e58a4;
    margin: 30px 0 50px;
  }
  .swiper {
    width: 1200px;
    height: 468px;
    border-radius: 8px;
    position: relative;
    .slide {
      position: relative;
      width: 100%;
      height: 100%;
      img.img {
        display: flex;
        height: 100%;
        width: 800px;
        object-fit: cover;
      }
      div.bg {
        position: absolute;
        right: 0;
        top: 0;
        width: 638px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        & > img {
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .info {
          display: flex;
          flex-direction: column;
          z-index: 10;
          padding: 108px 50px 30px 170px;
          span {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            line-height: 38px;
            &:first-child {
              font-size: 28px;
              font-weight: bold;
              line-height: 40px;
              margin-bottom: 36px;
            }
          }
        }
      }
    }
    .swiper-pagination {
      display: flex;
      justify-content: flex-end;
      position: relative;
      bottom: 56px;
      .pagination-wrap {
        display: flex;
        padding: 0 208px;
        z-index: 10;
        .pagination {
          width: 13px;
          height: 19px;
          background: rgba(255, 255, 255, 0.3);
          transform: skew(-10deg);
          position: relative;
          &.pagination-active {
            background: #ffffff;
            &:before {
              background: #ffffff;
            }
          }
          & + .pagination {
            margin-left: 60px;
          }
          &:before {
            position: absolute;
            top: 9px;
            right: 13px;
            content: "";
            width: 60px;
            height: 2px;
            background: rgba(255, 255, 255, 0.3);
          }
          &:first-child {
            &:before {
              width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
