<template>
  <div class="service-company-container">
    <div class="title">能量站企业账户合作模式</div>
    <div class="desc">
      企业通过接入能量站企业api，后台设定相关福利配置。员工通过注册绑定，在平台进行自由购买及兑换。后期能量站可根据企业需求，开发个性化福利模块。
    </div>
    <div class="intro1">
      <img class="bg" src="@/assets/company-img1.png" />
      <img class="image" src="@/assets/company-img2.png" />
      <div class="list">
        <div class="item">
          <img class="gou" src="@/assets/icon-gou.png" />
          <span class="text">企业账户员工购物享专属折扣</span>
        </div>
        <div class="item">
          <img class="gou" src="@/assets/icon-gou.png" />
          <span class="text">企业定制化服务（员工福利&商务馈赠）</span>
        </div>
      </div>
    </div>
    <div class="title">能量站企业账户合作模式——品牌对等</div>
    <div class="desc">
      首批合作用户为市值/政治级别/战略定位对等中国石油的一类账户企业
    </div>
    <div class="intro2">
      <img src="@/assets/company-img3.png" />
    </div>
    <div class="title">能量站业务输出</div>
    <div class="desc">
      能量站所有合作体系优先合作一类账户
    </div>
    <div class="intro3">
      <img src="@/assets/company-intro3.png" />
    </div>
    <div class="title">组建您的能量家族</div>
    <div class="family">
      <div>
        <img src="@/assets/company-img4.png" />
        <div class="info">
          能量站启用能量家族模式，经由介绍人介绍的合作企业所有员工为该介绍人的家族成员，家族成员在能量站购买商品时，介绍人（族长）均可获得相应奖励。
        </div>
      </div>
    </div>
    <div class="title">能量站企业账户合作流程</div>
    <div class="cooperation-process">
      <img class="bg" />
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in cooperationProcessData"
          :key="index"
        >
          <img :src="item.image" />
          <div class="item-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!--
    <div class="title">企业账户操作流程</div>
    <div class="operation">
      <img src="@/assets/company-img5.png" />
    </div>
    -->
  </div>
</template>
<script>
export default {
  data() {
    const cooperationProcessData = [
      {
        title: "商务谈判",
        image: require("@/assets/company-process1.png"),
      },
      {
        title: "企业入驻",
        image: require("@/assets/company-process2.png"),
      },
      {
        title: "员工消费",
        image: require("@/assets/company-process3.png"),
      },
    ];
    return { cooperationProcessData };
  },
};
</script>
<style lang="scss">
.service-company-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
  .title {
    font-size: 38px;
    font-weight: 600;
    color: #0a2349;
    line-height: 53px;
    margin-top: 120px;
  }
  .desc {
    width: 900px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #0a2349;
    line-height: 25px;
    margin-top: 40px;
  }
  .intro1 {
    width: 1200px;
    height: 430px;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    img.bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    img.image {
      position: relative;
      top: 1px;
      width: 429px;
      height: 429px;
    }
    .list {
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: 0 30px 0 90px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        & + .item {
          margin-top: 40px;
        }
        .gou {
          width: 25px;
          height: 24px;
          margin-right: 20px;
        }
        .text {
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
          line-height: 42px;
        }
      }
    }
  }
  .intro2 {
    width: 1200px;
    height: 266px;
    margin-top: 50px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .intro3 {
    width: 1440px;
    height: 360px;
    margin-top: 50px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .family {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    margin: 50px 0 0;
    padding: 43px 0;
    width: 100%;
    & > div {
      display: flex;
      width: 1200px;
      img {
        width: 375px;
        height: 402px;
      }
      & > div.info {

        flex: 1;
        font-size: 20px;
        font-weight: 400;
        color: #0a2349;
        line-height: 35px;
        margin-left: 107px;
        margin-top: 107px;
      }
    }
  }
  .cooperation-process {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 332px;
    position: relative;
    margin-top: 70px;
    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #0d57a3;
      width: 100%;
      height: 250px;
      z-index: -1;
    }
    .list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 1200px;
      .item {
        display: flex;
        flex-direction: column;
        width: 360px;
        border-radius: 8px;
        overflow: hidden;
        & + .item {
          margin-left: 50px;
        }
        img {
          height: 200px;
          width: 100%;
          background: cornflowerblue;
          object-fit: cover;
        }
        .item-title {
          width: 100%;
          height: 83px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
          color: #222222;
          line-height: 30px;
          background: #ffffff;
        }
      }
    }
  }
  .operation {
    width: 1200px;
    height: 317px;
    margin-top: 67px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
