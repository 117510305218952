<template>
  <Header :backgroundColor="headerBackgroundColor" />
  <div class="home-container">
    <Swiper
      class="bg"
      :autoplay="{ elay: 3000, disableOnInteraction: false }"
    >
      <SwiperSlide>
        <div class="bg">
          <img class="bg-video" src="https://birddubboimage.oss-accelerate.aliyuncs.com/upload/wosongjiu/image/202107/bf76e5b7-182b-451f-bf3a-2a2b51939651.png" />
          <div class="button bg-button" @click="showVideoModal(1)">
            观看视频
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="bg">
          <video
            class="bg-video"
            autoplay
            loop
            src="https://qnimg.didi315.com/video/intro.mp4"
            muted
          ></video>
          <div class="button bg-button" @click="showVideoModal(2)">
            观看视频
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <!-- <div class="bg">
      <video
        class="bg-video"
        autoplay
        loop
        src="https://qnimg.didi315.com/video/intro.mp4"
        muted
      ></video>
      <div class="button bg-button" @click="showVideoModal">观看视频</div>
    </div> -->
    <div v-if="isVideoModal" class="video-mask" @mousewheel="onMouseWheel">
      <div class="video-modal">
        <video
          id="videoIntro"
          class="video-intro"
          :src="videoSrc"
          preload="auto"
          autoplay
          :controls="true"
        ></video>
        <div class="button video-close" @click="hideVideoModal">x</div>
      </div>
    </div>
    <OfflineStore @apply="onApply" />
    <BusinessEnter @apply="onApply" />
    <CompanyDynamic />
    <ApplyModal :type="type" @close="type = ''" />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import OfflineStore from "@/components/OfflineStore.vue";
import BusinessEnter from "@/components/BusinessEnter.vue";
import CompanyDynamic from "@/components/CompanyDynamic.vue";
import ApplyModal from "@/components/ApplyModal.vue";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    OfflineStore,
    BusinessEnter,
    CompanyDynamic,
    ApplyModal,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      headerBackgroundColor: "transparent",
      player: undefined,
      isVideoModal: false,
      type: "",
      videoSrc: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    onApply(type) {
      this.type = type;
    },
    onMouseWheel(event) {
      event.preventDefault();
    },
    showVideoModal(num) {
      if (num === 1) {
        this.videoSrc = "https://qnimg.didi315.com/video/video-intro1.mp4";
      } else {
        this.videoSrc = "https://qnimg.didi315.com/video/video-intro2.mp4";
      }
      this.isVideoModal = true;
    },
    hideVideoModal() {
      this.isVideoModal = false;
    },
    handleScroll(event) {
      const scrollTop =
        event.target.documentElement.scrollTop || event.target.body.scrollTop;
      if (scrollTop > 100) {
        const opacity = (scrollTop - 100) / 150;
        this.headerBackgroundColor = `rgba(27,35,137,${opacity})`;
      } else {
        this.headerBackgroundColor = "transparent";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  display: flex;
  flex-direction: column;
  .bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 645px;
    // height: 725px;
    position: relative;
    &-title {
      font-size: 53px;
      font-weight: 600;
      color: #ffffff;
      line-height: 75px;
      &:nth-child(2) {
        margin-top: 150px;
      }
    }
    &-button {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      margin: auto;
      width: 170px;
      height: 44px;
      border-radius: 30px;
      border: 1px solid #ffffff;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      background: transparent;
    }

    &-video {
      object-fit: fill;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  .video-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 20, 40, 0.9);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-modal {
      position: relative;
      padding: 50px;
      .video-intro {
        width: 800px;
        height: 450px;
      }
      .video-close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 30px;
        color: #ffffff;
      }
    }
  }
}
</style>
