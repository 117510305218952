<template>
  <div v-if="!!visiable" class="apply-mask">
    <div class="apply-modal">
      <div>
        <div class="button apply-close" @click="close">X</div>
        <div class="apply-title">渠道合作</div>
        <div class="apply-info">
          <div class="apply-tabs">
            <div :class="{ active: tabIndex === index }" v-for="(item, index) in applyData" :key="index"
              @click="clckTab(index)">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="apply-content">
            <div class="apply-card">
              <div class="card-info">
                <span class="info-name">{{ applyData[tabIndex].name }}</span>
                <span>{{ applyData[tabIndex].mobile }}</span>
                <span>{{ applyData[tabIndex].email }}</span>
              </div>
              <img class="card-qrcode" :src="applyData[tabIndex].card" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
  },
  data() {
    const applyData = [
      {
        type: "store",
        title: "门店合作",
        card: require("@/assets/card-store.png"),
        mobile: "15238081752",
        email: "WTwangyadong@nengliangwang.com",
        name: "王亚东",
      },
      {
        type: "supplier",
        title: "供应商入驻",
        card: require("@/assets/card-supplier.png"),
        mobile: `徐溱：13761052761\n徐溱：xuzhen@nengliangwang.com`,
        email: "陈少华：13609733509\n陈少华：chenshaohua@nengliangwang.com",
        name: "总部：徐溱\n广州分部：陈少华",
      },
      {
        type: "company",
        title: "企业入驻",
        card: require("@/assets/card-company.png"),
        mobile: "13867152560",
        email: "zhangtianyong@nengliangwang.com",
        name: "张天勇",
      },
    ];
    return { applyData, tabIndex: undefined, visiable: false };
  },
  watch: {
    type(newValue) {
      this.tabIndex = this.applyData.findIndex(
        (item) => item.type === newValue
      );
      if (this.tabIndex !== undefined && this.tabIndex !== -1) {
        this.visiable = true;
      }
    },
  },
  mounted() { },
  methods: {
    clckTab(index) {
      this.tabIndex = index;
    },
    close() {
      this.visiable = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.apply-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 20, 40, 0.9);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .apply-modal {
    width: 700px;
    background: #ffffff;
    border-radius: 20px;
    padding: 36px 0;
    position: relative;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .apply-close {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 34px;
        height: 34px;
        background: #f9f9f9;
        border-radius: 4px;
        font-size: 16px;
        color: #757580;
      }

      .apply-title {
        font-size: 28px;
        font-weight: bold;
        color: #2f2e3f;
        line-height: 40px;
      }

      .apply-info {
        width: 637px;
        overflow: hidden;
        margin-top: 70px;

        .apply-tabs {
          display: flex;
          height: 56px;
          background: #fcfbfc;

          &>div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            color: #7c7c7c;
            line-height: 25px;
            border: 1px solid #a3b7da;

            &:hover {
              cursor: pointer;
            }

            &.active {
              background: #ecf5ff;
              border: 1px solid #175499;
              color: #175499;
            }

            &:first-child {
              border-radius: 8px 0px 0px 0px;
            }

            &:last-child {
              border-radius: 0px 8px 0px 0px;
            }
          }
        }

        .apply-content {
          display: flex;
          align-items: center;
          border: 1px solid #a3b7da;
          border-radius: 0px 0px 8px 8px;
          border-top: none;
          height: 240px;

          .apply-card {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 90px 0 50px;
            justify-content: space-between;

            .card-info {
              display: flex;
              flex-direction: column;

              &>span {
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 24px;
                white-space: pre-wrap;
                text-align: left;
              }

              &>span.info-name {
                font-size: 24px;
                font-weight: bold;
                color: #222222;
                line-height: 32px;
                margin-bottom: 14px;
              }
            }

            .card-qrcode {
              width: 186px;
              height: 186px;
            }
          }
        }
      }

      .apply-contact {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        &>div {
          font-size: 18px;
          font-weight: 500;
          color: #222222;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
